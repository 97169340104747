.day,
.month,
.year {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    margin-left: 5px;
}

.day,
.month {
    margin-right: 5px;
}

body,
#root {
    padding-top: 60px;
    padding-bottom: 0px;
    background-color: #0e234d;
}

.header {
    text-align: center;
    margin-bottom: 50px;
}

.form-signin {
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}

.form-max {
    margin: 0 auto;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
    margin-bottom: 10px;
}

.form-signin .form-control {
    position: relative;
    box-sizing: border-box;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.title-center {
    text-align: center;
    margin-bottom: 50px;
}

.mt-30 {
    margin-top: 30px;
}

.form-register {
    max-width: 500px;
    padding: 15px;
    margin: 0 auto;
}

.link-back {
    text-align: center !important;
}

.gif {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

#no-margin {
    margin: 0 !important;
}

/* LOADER */

.loader {
    position: absolute;
    top: calc(50% - 50px);
    bottom: calc(50% - 50px);
    right: calc(50% - 50px);
    left: calc(50% - 50px);
    width: 100px;
    z-index: 15;
    display: none;
}

.sorry-section .logo img {
    width: 200px;
}

.sorry-section .icon-emoji {
    margin-top: 50px;
}

.sorry-section h4 {
    color: white;
    font-size: 14px;
    margin: 0 auto;
    width: 220px;
}

.sorry-section h3 {
    color: white;
    margin-bottom: 20px;
    text-align: center;
}

.welcome-section .logo img {
    width: 200px;
}

.welcome-section .icon-logo {
    margin-top: 150px;
}

.bottom-img {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
}

.bottom-img img {
    width: 375px;
}

.btn-telegram {
    margin-bottom: 100px;
}

.btn-telegram .btn-green {
    background-color: #aec836;
    border-radius: 10px;
    color: #0e234d;
    font-weight: 700;
    font-size: 14px;
    margin: 0 auto;
    width: 100%;
    line-height: 22px;
    padding: 20px 0;
    text-align: center;
    display: block;
}

.btn-telegram span {
    color: white;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 10px;
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}

.telegram {
    color: #aec836;
}

@media screen and (max-width: 420px) {
    .bottom-img img,
    .btn-telegram .btn-green {
        width: 100%;
    }
    .content-form {
        height: 2100px !important;
    }
}

#buttonSubmit {
    margin-top: 5vw;
}

/*Nuevo diseño*/

label {
    color: #ffffff;
}

.dropdown-container .dropdown-content .panel-content .select-panel .options li span {
    color: black !important;
}

.container {
    /* background-image: url("../images/fondo.png"); */
    /* position: absolute; */
    width: 390px;
    height: 612px;
    left: 0px;
    top: 0px;
}

#welcome {
    position: absolute;
    width: 327px;
    height: 43px;
    /* left: 24px; */
    /* font-family: 'Montserrat'; */
    top: 120px;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 22px;
    /* display: flex; */
    align-items: center;
    text-align: center;
    color: #ffffff;
}

#logo_principal {
    position: absolute;
    width: 216px;
    height: 65px;
    left: 80px;
    top: 274px;
}

#btn_welcome {
    position: absolute;
    width: 320px;
    height: 45px;
    left: 15px;
    top: 150px;
    background: #aec836;
    border-radius: 10px;
    color: #0e234d;
}

#logo_secundario {
    position: absolute;
    width: 160px;
    height: 48.15px;
    left: 108px;
    top: -15px;
}

.logo_form {
    top: 55px !important;
}

.titulo_principal {
    /* position: absolute; */
    width: 219px;
    height: 34px;
    left: calc(50% - 219px / 2);
    top: calc(50% - 34px / 2 - 64.75px);

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    /* or 121% */

    /* display: flex; */
    align-items: center;
    text-align: center;

    color: #0e234d;
    margin-top: 30px;
    margin-bottom: 30px;
}

.container-2 {
    position: absolute;
    width: 395px;
    height: 630px;
    left: 0px;
    top: 95px;

    background: #f5f5f5;
    border-radius: 10px 10px 0px 0px;
}

.container-2 .health-banner {
    position: absolute;
    left: 0;
    top: -21px;
    border-radius: 10px 10px 0 0;
}

.sorry-section .health-banner {
    margin-bottom: 30px;
}

.btn_principal {
    /* position: absolute; */
    width: 153px;
    height: 40px;
    left: calc(50% - 153px / 2 + 84px);
    /* top: 113px; */

    background: #0e234d;
    border-radius: 6px;

    /* Button-label */

    /* font-family: 'Roboto'; */
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 133% */

    /* display: flex; */
    align-items: center;
    text-align: center;
    letter-spacing: 0.3px;

    /* White (#FFFFFF) */

    color: #ffffff;
}
.submit {
    width: 323px !important;
    height: 40px !important;
}

.btn_secundario {
    background: #ffffff;
    color: #0e234d;
    width: 153px;
    height: 40px;
    left: calc(50% - 153px / 2 + 84px);

    /* display: flex; */
    align-items: center;
    text-align: center;
    letter-spacing: 0.3px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    border-color: #0e234d;
}

.btn {
    white-space: normal;
    height: auto;
}

input {
    border: 1px solid rgba(126, 122, 122, 0.14) !important;
    background: rgba(232, 232, 232, 0.85) !important;
    border-radius: 8px !important;
}

select {
    border: 1px solid rgba(126, 122, 122, 0.14) !important;
    background: rgba(232, 232, 232, 0.85) !important;
    border-radius: 8px !important;
}
.label-text {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 28px;
    /* identical to box height, or 280% */
    align-items: center;

    /* Black-900 (#1A1A1A) */
    color: #1a1a1a;
}

.terminos {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: justify;
    color: #000000;
}

.content-form {
    height: 2000px;
    top: 175px !important;
}

.label-form {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    color: #000000;
}

#term {
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    /* identical to box height */
    color: #555555;
}

.label-white {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 17px;
    /* identical to box height, or 57% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
}

.message {
    position: absolute;
    top: 108px;
}

.translation-icon {
    width: 25px;
    cursor: pointer;
    transition: opacity 0.3s;
}

.translation-icon:hover {
    opacity: 0.8;
}

.translation-icon__disabled {
    display: none;
}

